import React from "react"

import SEO from "@/components/seo"
import Sizing from "@/components/general/Sizing"
import styles from "@/styles/privacy.module.scss"
import shareImg from "@/images/og/og_share_spanish.jpg"

import { Link } from "gatsby"

const PrivacyPolicy = () => (
    <>
        <SEO
            title="Free Markets Destroy | Política de Privacidad"
            description="La Política de privacidad de Washington Policy Center se aplica cuando visita o utiliza nuestros sitios web, aplicaciones y otros servicios, incluidos los eventos, que hacen referencia a esta política de privacidad o se vinculan a ella. Obtenga más información sobre cómo recopilamos y administramos sus datos."
            img={shareImg}
            lang="es"
        />

        <div className={styles.pad}></div>
        <div className={styles.main}>
            <span className="copy h2">
                {" "}
                Política de privacidad del Washington Policy Center
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                Acerca de esta política de privacidad
            </span>
            <span className="copy">
                Esta política de privacidad se aplica cuando visita o utiliza nuestros sitios web, aplicaciones y otros servicios, incluidos los eventos, que hacen referencia o enlazan a esta política de privacidad (cada uno, un "Servicio"). Esta política de privacidad puede complementarse con declaraciones de privacidad, términos o avisos adicionales proporcionados en ciertas áreas del Servicio o durante nuestras interacciones con usted.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                Información que recopilamos
            </span>
            <span className="copy">
                Recopilamos información sobre usted de tres maneras: directamente de sus comentarios, a través de las tecnologías del Servicio y de fuentes de terceros. Esto puede incluir la recopilación de sus datos de formularios de registro, escáneres de tarjetas de presentación / credenciales, registros de correo electrónico y otras aplicaciones relacionadas con eventos.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                Datos que nos proporciona
            </span>
            <ul>
                <li>
                    Los tipos de información personal que recopilamos directamente de usted dependen de cómo interactúa con nosotros y el Servicio e incluye:
                </li>
                <li>
                    Datos de contacto, como su nombre, dirección de correo electrónico, identificador de redes sociales, dirección postal y número de teléfono
                </li>
                <li>
                    Otra cuenta o registro de eventos e información de perfil, como antecedentes educativos y profesionales y fotos, y para eventos, requisitos dietéticos y de accesibilidad
                </li>
                <li>
                    Información de pago, como un número de tarjeta de crédito o débito
                </li>
                <li>
                    Comentarios, retroalimentación y otra información que nos proporcione, incluidos los datos de consulta de búsqueda y las preguntas o información que nos envíe
                </li>
            </ul>
            <span className={"copy copy--lead " + styles.heading}>
                {" "}
                Datos del servicio, aplicaciones y uso del sitio web
            </span>
            <span className="copy">
                El Servicio recopila automáticamente información sobre cómo usted y su dispositivo interactúan con el Servicio, que incluye:
            </span>
            <ul>
                <li>
                    Información de la computadora, el dispositivo y la conexión, como la dirección IP, el tipo y la versión del navegador, el sistema operativo y otro software instalado en su dispositivo, la plataforma móvil y el identificador único del dispositivo y otros identificadores técnicos, informes de errores y datos de rendimiento
                </li>
                <li>
                    Datos de uso, como las funciones que utilizó, la configuración que seleccionó, los datos del flujo de clics en la URL, incluida la marca de fecha y hora, y las páginas de referencia y salida, y las páginas que visitó o buscó en el Servicio
                </li>
                <li>
                    Para los Servicios con reconocimiento de ubicación, la región, ciudad o pueblo donde se encuentra su dispositivo para brindarle contenido más relevante para el lugar del mundo en el que se encuentra
                </li>
            </ul>
            <span className="copy">
                Recopilamos estos datos a través de nuestros servidores y el uso de cookies y otras tecnologías. La información obtenida de las cookies y vinculada a información de identificación personal se trata como información personal. Puede controlar las cookies a través de la configuración de su navegador y otras herramientas. Sin embargo, si bloquea ciertas cookies, es posible que no pueda registrarse, iniciar sesión o acceder a ciertas partes o hacer un uso completo del Servicio.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                Datos de otras fuentes
            </span>
            <span className="copy">
                También podemos obtener información personal sobre usted de otros terceros, que incluyen:
            </span>
            <ul>
                <li>
                    Redes sociales cuando otorga permiso para acceder a sus datos en una o más redes
                </li>
                <li>
                    Nuestros proveedores de servicios que nos ayudan a determinar una ubicación basada en su dirección IP para personalizar el Servicio a su ubicación
                </li>
                <li>
                    Socios con los que ofrecemos servicios de marca compartida o participamos en actividades de marketing conjuntas o patrocinio y alojamiento de eventos
                </li>
                <li>
                    Fuentes y proveedores de datos disponibles públicamente de los que obtenemos datos para validar o complementar la información que tenemos.
                </li>
            </ul>
            <span className={"copy copy--lead " + styles.heading}>
                Cómo usamos su información
            </span>
            <span className="copy">
                Dependiendo de cómo interactúe con nosotros y el Servicio y sus preferencias, usamos su información personal para:
            </span>
            <ul>
                <li>
                    Proporcionar, activar y administrar su acceso y uso del Servicio
                </li>
                <li>
                    Procesar y completar una solicitud, pedido, descarga, suscripción u otra transacción (incluso para procesar pagos)
                </li>
                <li>
                    Brindar soporte técnico, de productos y de otro tipo y ayudar a mantener el Servicio en funcionamiento, seguro y protegido
                </li>
                <li>
                    Mejorar y mejorar el Servicio y nuestros otros productos y servicios y desarrollar nuevos productos, servicios y beneficios
                </li>
                <li>
                    Ofrecerle contenido personalizado y otra personalización para que el Servicio sea más relevante para sus intereses y geografía
                </li>
                <li>
                    Responder a sus solicitudes, consultas, comentarios e inquietudes
                </li>
                <li>
                    Notificarle sobre cambios, actualizaciones u otros anuncios relacionados con el Servicio y nuestros otros productos y servicios
                </li>
                <li>
                    Entregar publicidad dirigida, mensajes promocionales, avisos y otra información relacionada con el Servicio, sus intereses y otros productos, eventos y servicios
                </li>
                <li>
                    Para eventos: para incluir sus detalles profesionales en folletos de delegados, insignias, pases, tarjetas de lugar, listas de invitados, listas de asientos, portales de redes y, cuando corresponda, para listas de oradores y presentadores de paneles
                </li>
                <li>
                    Invitarlo a participar en pruebas y encuestas de usuarios, así como en sorteos, concursos y promociones similares
                </li>
                <li>
                    Identificar tendencias de uso y desarrollar análisis de datos, incluso con fines de investigación, auditoría, informes y otras operaciones comerciales, incluida la determinación de la efectividad de nuestras campañas promocionales y la evaluación de nuestro desempeño comercial, o de otras formas de conformidad con un acuerdo con el cliente
                </li>
                <li>
                    Cumplir con nuestras obligaciones legales, resolver disputas y hacer cumplir nuestros acuerdos.
                </li>
            </ul>
            <span className={"copy copy--lead " + styles.heading}>
                {" "}
                Compartir su información
            </span>
            <span className="copy">
                Nuestra política es no compartir la información personal que recopilamos de usted a través de nuestros Servicios con terceros, excepto como se describe en esta Política o como se divulga de otra manera.{" "}
            </span>
            <span className="copy">
                Por ejemplo, podemos compartir información personal de la siguiente manera:{" "}
            </span>
            <ul>
                <li>
                    con proveedores, consultores y otros proveedores de servicios cuando nos da su consentimiento para hacerlo, incluso si le notificamos, que la información que proporcione se compartirá de una manera particular y que usted proporcione dicha información
                </li>
                <li>
                    cuando creemos de buena fe que estamos legalmente autorizados o obligados a hacerlo o que hacerlo es razonablemente necesario o apropiado para cumplir con la ley o los procesos legales o responder a solicitudes, reclamos o autoridades legales legales, incluida la respuesta a citaciones legales, órdenes judiciales u órdenes judiciales
                </li>
                <li>
                    cuando creemos de buena fe que hacerlo es razonablemente necesario o apropiado para responder a reclamos o para proteger los derechos, la propiedad o la seguridad de Washington Policy Center, nuestros usuarios, nuestros empleados, nuestros voluntarios, propietarios de derechos de autor, terceros o el público , incluido, entre otros, proteger a dichas partes de actividades o usos fraudulentos, abusivos, inapropiados o ilegales de nuestro Sitio
                </li>
                <li>
                    para hacer cumplir o aplicar esta Política, nuestros Términos de servicio o nuestras otras políticas o acuerdos; y en relación con, o durante las negociaciones de, cualquier fusión, reorganización, adquisición, venta de activos, financiación o transacción de préstamo o en cualquier otra situación en la que la información personal pueda ser divulgada o transferida como uno de los activos de Washington Policy Center.{" "}
                </li>
            </ul>
            <span className="copy">
                No somos responsables de las acciones de ningún proveedor de servicios u otros terceros, ni somos responsables de ninguna información adicional que proporcione directamente a ningún tercero, y le recomendamos que se familiarice con sus prácticas de privacidad antes de divulgar información directamente a dichos terceros. fiestas. Nada en este documento restringe el intercambio de información agregada o anónima, que puede compartirse con terceros sin su consentimiento.
            </span>
            <span className="copy">
                Peticiones en línea: cuando firma una petición en línea, comprende que dicha petición es información pública y que podemos hacerla, y su nombre, ciudad, estado y cualquier comentario proporcionado en relación con la misma a disposición del público. Además, podemos proporcionar tales peticiones o compilaciones de las mismas, incluidos sus comentarios, nombre, ciudad y estado a los líderes estatales o locales, o a la prensa.
            </span>
            <span className="copy">
                Enlaces a otros sitios web: nuestros sitios pueden contener enlaces a otros sitios web. Cualquier información personal que proporcione en las páginas vinculadas se proporciona directamente a ese tercero y está sujeta a la política de privacidad de ese tercero. Esta Política no se aplica a dichos sitios vinculados, y no somos responsables del contenido o las prácticas y políticas de privacidad y seguridad de estos sitios web o cualquier otro sitio que esté vinculado desde nuestros Sitios. Le recomendamos que conozca sus prácticas y políticas de privacidad y seguridad antes de proporcionarles información personal.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                Razones legales
            </span>
            <span className="copy">
                También divulgamos su información personal si creemos de buena fe que dicha divulgación es necesaria para:
            </span>
            <ul>
                <li>
                    Cumplir con cualquier ley, regulación, proceso legal u otra obligación legal aplicable
                </li>
                <li>
                    detectar, investigar y ayudar a prevenir problemas de seguridad, fraudes o técnicos
                </li>
                <li>
                    proteger los derechos, la propiedad o la seguridad de WASHINGTON POLICY CENTER, nuestros usuarios, empleados y otros
                </li>
            </ul>
            <span className={"copy copy--lead " + styles.heading}>
                {" "}
                Sus preferencias de comunicación
            </span>
            <span className="copy">
                {" "}
                Puede personalizar y administrar sus preferencias de comunicación y sus datos de contacto enviando un correo electrónico a WPC@washingtonpolicy.org. Nos reservamos el derecho de notificarle los cambios o actualizaciones del Servicio cuando sea necesario.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                {" "}
                Acceso y actualización de su información
            </span>
            <span className="copy">
                {" "}
                El Servicio permite a los usuarios revisar la información de su cuenta mediante una solicitud por correo electrónico a WPC@washingtonpolicy.org para realizar correcciones o actualizaciones. Mantener dicha información actualizada es responsabilidad exclusiva del usuario.{" "}
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                {" "}
                Retención de datos
            </span>
            <span className="copy">
                {" "}
                Retenemos su información personal durante el tiempo que sea necesario para proporcionar el Servicio y cumplir con las transacciones que ha solicitado, y para otros fines esenciales, como cumplir con nuestras obligaciones legales, mantener registros comerciales y financieros, resolver disputas, mantener la seguridad, detectar y prevenir fraude y abuso, y hacer cumplir nuestros acuerdos.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                {" "}
                Privacidad de los niños
            </span>
            <span className="copy">
                {" "}
                No recopilamos deliberadamente información de niños menores de 13 años ni dirigimos el Servicio a niños menores de 13 años.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                {" "}
                Seguridad de datos
            </span>
            <span className="copy">
                {" "}
                Utilizamos una variedad de medidas de seguridad administrativas, físicas y técnicas para ayudar a proteger su información personal.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                {" "}
                Ubicaciones de procesamiento
            </span>
            <span className="copy">
                {" "}
                Su información personal puede almacenarse y procesarse en su región u otro país donde el Washington Policy Center y nuestros proveedores de servicios mantienen servidores e instalaciones, incluidos Australia, Brasil, Francia, Alemania, Italia, Irlanda, los Países Bajos, Singapur, Sudáfrica, el Reino Unido y Estados Unidos. Tomamos medidas, incluso a través de nuestros contratos, para garantizar que la información continúe protegida donde sea que se encuentre de manera consistente con los estándares de protección requeridos por la ley aplicable.
            </span>
            <span className="copy">
                {" "}
                Cuando la información personal se transfiere desde el Espacio Económico Europeo ("EEE") o Suiza a un país que no ha recibido una decisión de adecuación por parte de la Comisión Europea, nos basamos en las salvaguardas apropiadas, como las Cláusulas Contractuales Estándar aprobadas por la Comisión Europea y las UE-EE. UU. y Suiza-EE. UU. Privacy Shield Frameworks, para transferir los datos.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                {" "}
                Motivos para el procesamiento
            </span>
            <span className="copy">
                {" "}
                Cuando recopilamos de usted cualquier información personal dentro del alcance de las leyes europeas de protección de datos, lo hacemos (a) con su consentimiento; (b) cuando sea necesario para proporcionar el Servicio, completar una transacción o ejecutar un contrato con usted o en su solicitud antes de celebrar un contrato; (c) cuando sea necesario para nuestro cumplimiento de una obligación legal; (d) cuando sea necesario para el desempeño de una tarea realizada en interés público; y / o (e) según sea necesario para satisfacer nuestros intereses legítimos como se describe a continuación, excepto cuando nuestros intereses sean anulados por sus derechos de privacidad. Cuando dependemos de su consentimiento para procesar información personal, usted tiene derecho a retirar su consentimiento en cualquier momento, y cuando nos basamos en intereses legítimos, tiene derecho a oponerse.
            </span>
            <span className="copy">
                {" "}
                Nuestros intereses legítimos son: entregar nuestros productos a nuestros clientes y sus usuarios finales; para detectar o prevenir el fraude; para proteger la seguridad de nuestros sistemas, clientes y usuarios; seguir desarrollando nuestros productos; para realizar marketing directo; para operar nuestro negocio (mediante el procesamiento de pagos / facturas y realizar verificaciones de crédito de clientes, proveedores y clientes potenciales, realizar la gestión de ventas y relaciones con los clientes, la gestión de proveedores, así como el procesamiento incidental en nuestra oficina administrativa para estos fines); para permitir que nuestros clientes cumplan con las obligaciones legales; para proporcionar servicio o soporte al cliente; o cumplir con nuestros otros intereses legítimos como se describe anteriormente.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                {" "}
                Tus derechos
            </span>
            <span className="copy">
                {" "}
                Tiene derecho, en virtud de las leyes europeas y de otras leyes de privacidad y protección de datos, a solicitar de forma gratuita:
            </span>
            <ul>
                <li>
                    acceso y corrección o eliminación de su información personal
                </li>
                <li>
                    que cesemos o limitemos el procesamiento de su información personal
                </li>
                <li>
                    una copia de su información personal (derecho a la portabilidad de datos)
                </li>
                <li>
                    oponerse en cualquier momento al procesamiento de la información personal que le concierne para marketing directo.
                </li>
            </ul>
            <span className="copy">
                Si desea ejercer alguno de estos derechos, comuníquese con nosotros a la dirección que se indica a continuación. Responderemos a su solicitud de acuerdo con las leyes aplicables. Para proteger su privacidad y seguridad, es posible que le solicitemos que verifique su identidad.
            </span>
            <span className={"copy copy--lead " + styles.heading}>Cambios</span>
            <span className="copy">
                Actualizaremos esta política de privacidad de vez en cuando. Cualquier cambio se publicará en esta página con una fecha de revisión actualizada. Si realizamos cambios sustanciales, lo notificaremos a través del Servicio o por otros medios.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                Contáctenos
            </span>
            <span className="copy">
                Si tiene alguna pregunta, comentario o solicitud con respecto a esta política de privacidad o nuestro procesamiento de su información, comuníquese con:
            </span>
            <span className={styles.mono}>
                Operations Manager <br />
                PO Box 3643 <br />
                Seattle, WA 98124-3643{" "}
            </span>

            <a href="mailto:WPC@washingtonpolicy.org">
                {" "}
                <span className={styles.contact}>WPC@washingtonpolicy.org</span>
            </a>
            <span className={"copy copy--lead " + styles.heading}>
                Como quejarse
            </span>
            <span className="copy">
                Esperamos poder resolver cualquier consulta o inquietud que plantee sobre el uso que hacemos de su información, pero también puede presentar una queja ante la autoridad de protección de datos de la jurisdicción correspondiente.
            </span>
            <span className={"copy " + styles.mono}>
                <br />
                Última actualización: 19/6/2020
            </span>
        </div>
    </>
)

export default PrivacyPolicy
